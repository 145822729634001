import axios from 'axios';

let BASE_URL = 'https://bevrly.com';

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://localhost:8000'
}

export function bottleSearchApi(query) {
  if (!query) {
    return;
  }
  const endpoint = `/wine_bottles/search/?q=${query}`

  return axios.get(`${BASE_URL}${endpoint}`)
    .then(res => {
      return res.data.results;
    });
}

function createResource(apiEndpoint) {
  return (query) => {
    let status = "loading";
    let result;
    let suspender;
    if (query) {
      suspender = apiEndpoint(query)
        .then(data => {
          status = "success"
          result = data
        })
        .catch(error => {
          status = "error"
          result = error
        });
    } else {
      status = "success";
      result = [];
    }

    return {
      read() {
        if (status === "loading") {
          throw suspender
        } else if (status === "error") {
          throw result
        } else if (status === "success") {
          return result
        }
      }
    }
  }
}

export const bottleSearchResource = createResource(bottleSearchApi);
