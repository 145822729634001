import React, { useState, useEffect, Suspense, useLayoutEffect } from 'react';
import {
  Title,
  SearchWrapper,
  Container,
  Input,
  MiniTitle,
  SearchInputContainer,
  TypeaheadContainer,
  TypeaheadListWrapper,
  RowWrapper,
  Line,
  ResultsWrapper,
  ResultRowWrapper,
  ResultTitle,
  ResultSubTitle,
  MetaWrapper,
  MetaTitle,
  MetaValue,
  MetaItemWrapper,
  CloseButton,
} from './Search.styles';
import { bottleSearchResource } from '../api';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

function TypeaheadRow({ data, onClick, ...props }) {
  return (
    <RowWrapper onClick={onClick}>
      {data.label}
    </RowWrapper>
  );
}

function TypeaheadList({ results, onClick, ...props }) {
  const [transition, setTransition] = useState(false);

  useLayoutEffect(() => {
    setTransition(false);
  }, [results])

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 10)
  }, [results]);

  return (
    <TypeaheadListWrapper
      transition={transition}
    >
      {results.slice(0, 10).map(d => <TypeaheadRow
        key={d.label}
        data={d}
        onClick={() => onClick(d)}
      />)}
    </TypeaheadListWrapper>
  );
}

function CenteredSpinner() {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Loader type="Puff" color="#00BFFF" height={80} width={80}/>
    </div>
  );
}

function LoadingTypeahead() {
  return (
    <TypeaheadContainer>
      <Line />
      <CenteredSpinner />
    </TypeaheadContainer>
  );
}

function LoadingResultsPage() {
  return (
    <ResultsWrapper>
      <CenteredSpinner />
    </ResultsWrapper>
  );
}

function Typeahead({ resource, onClick, ...props }) {
  const results = resource.read();

  return (
    <TypeaheadContainer>
      <Line />
      <TypeaheadList results={results} onClick={onClick} />
    </TypeaheadContainer>
  );
}

function SearchInput({ showResults, resource, onChange, onFinalize, ...props }) {
  const [state, setState] = useState('');
  const [showTypeahead, setShowTypeahead] = useState(false);

  const finalize = (query) => {
    onFinalize(query);
    setShowTypeahead(false);
  }

  useEffect(() => {
    window.addEventListener('click', () => {
      setShowTypeahead(false);
    });
  }, []);

  return (
    <SearchInputContainer
      showResults={showResults}
    >
      <Input
        placeholder="Search..."
        value={state}
        onChange={e => {
          setState(e.target.value)
          onChange(e.target.value);
          if (e.target.value) {
            setShowTypeahead(true);
          } else {
            setShowTypeahead(false);
          }
        }}
        onClick={e => {
          e.stopPropagation();
          if (state) {
            setShowTypeahead(true);
          }
        }}
        onKeyPress={e => {
          if(e.key === 'Enter'){
            finalize(e.target.value);
          }
        }}
        showTypeahead={showTypeahead}
      />
      {showTypeahead && (
        <CloseButton
          onClick={() => {
            setShowTypeahead(false);
            setState('');
            onChange('');
          }}
        >
          +
        </CloseButton>
      )}
      { showTypeahead && (
        <Suspense fallback={<LoadingTypeahead />}>
          <Typeahead
            resource={resource}
            onClick={row => {
              setState(row.label);
              finalize(row.label);
            }}
          />
        </Suspense>
      )}
    </SearchInputContainer>
  );
}

function MetaItem({ title, value, ...props }) {
  return (
    <MetaItemWrapper>
      <MetaTitle>
        {title}
      </MetaTitle>
      <MetaValue>
        {value}
      </MetaValue>
    </MetaItemWrapper>
  );
}

function MetaData({ data, ...props }) {
  return (
    <MetaWrapper>
      <MetaItem
        title="Variety"
        value={data.variety_label}
      />
      <MetaItem
        title="Region"
        value={data.region_label}
      />
      { data.abv > 0 && (
        <MetaItem
          title="ABV"
          value={`${data.abv / 100}%`}
        />
      ) }
      { false && (
        <>
          <MetaItem
            title="Vineyard"
            value="something"
          />
          <MetaItem
            title="Appellation"
            value="something"
          />
        </>
      ) }
      <div style={{ width: '50%' }}></div>
    </MetaWrapper>
  )
}

function ResultsRow({ data, ...props }) {
  const bottles = data.bottles.sort((a, b) => {
    let bottleA;
    let bottleB;
    try {
      bottleA = parseInt(a.vintage);
    } catch(e) {
      return 1;
    }
    try {
      bottleB = parseInt(b.vintage);
    } catch(e) {
      return -1;
    }
    if (bottleA > bottleB) {
      return -1;
    } else if (bottleA < bottleB) {
      return 1;
    }
    return 0;
  }).map(bottle => {
    if (!bottle.vintage) {
      bottle.vintage = '';
    }
    return bottle;
  });

  return (
    <ResultRowWrapper>
      <ResultTitle>
        {`${bottles[0].vintage} ${data.label}`}
      </ResultTitle>
      <ResultSubTitle>
        {data.producer_label}
      </ResultSubTitle>
      <MetaData data={data} />
      <Line width="100%" />
    </ResultRowWrapper>
  )
}

function SearchResults({ resource, ...props }) {
  const results = resource.read();

  return (
    <ResultsWrapper>
      {results.slice(0, 10).map(d => <ResultsRow
        key={d.label}
        data={d}
      />)}
    </ResultsWrapper>
  );
}

export default function Search({ ...props }) {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTypeaheadResults, setSearchTypeaheadResults] = useState([]);
  const [showResults, setShowResults] =  useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [fadeTitle, setFadeTitle] = useState(false);

  useEffect(() => {
    if (showResults) {
      setTimeout(() => {
        setShowTitle(false);
        setFadeTitle(false);
      }, 400);
    } else {
      setShowTitle(true);
      setTimeout(() => {
        setFadeTitle(true);
      }, 200);
    }
  }, [showResults]);

  return (
    <>
      <SearchWrapper showResults={showResults}>
        <Container>
          {showTitle && (
            <Title
              showResults={showResults}
              fade={fadeTitle}
            >
              Bevrly Search
            </Title>
          )}
          {showResults && (
            <MiniTitle
              fade={!fadeTitle}
            >
              Bevrly
            </MiniTitle>
          )}
          <SearchInput
            showResults={showResults}
            resource={searchTypeaheadResults}
            onChange={query => {
              setSearchTypeaheadResults(bottleSearchResource(query));
            }}
            onFinalize={(query) => {
              setShowResults(true);
              setSearchResults(bottleSearchResource(query));
            }}
          />
        </Container>
      </SearchWrapper>
      {showResults && (
        <Suspense fallback={<LoadingResultsPage />}>
          <SearchResults resource={searchResults} />
        </Suspense>
      )}
    </>
  );
}
