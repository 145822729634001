import React, { useState } from 'react';
import Normalize from './global/Normalize';
import GlobalStyles from './global/GlobalStyles';
import Search from './components/Search';

function App() {
  return (
    <>
      <Normalize />
      <GlobalStyles />
      <Search />
    </>
  );
}

export default App;
