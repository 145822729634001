import React from 'react';
import styled, { css } from 'styled-components';

export const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.08em;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: ${props => {
    if (props.fade) {
      return '1';
    }
    return '0';
  }};
  transition: opacity 2s ease;
  z-index: 0;

  @media screen and (min-width: 320px) {
    top: 30px;
    font-size: 24px;
  }
  @media screen and (min-width: 630px) {
    top: 180px;
    font-size: 48px;
  }

  /* White */
  color: #FFFFFF;
  ${props => props.showResults ? 'animation: fadeOut 5s;': ''}

  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
`;

export const MiniTitle = styled.h1`
  width: 188px;
  height: 73px;
  left: 0px;
  top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.08em;

  @media screen and (max-width: 630px) {
    opacity: 0;
  }

  /* White */

  color: #FFFFFF;

  opacity: ${props => {
    if (props.fade) {
      return '1';
    }
    return '0';
  }};
  transition: opacity 2s ease;
`;

export const SearchWrapper = styled.div`
  max-width: 100vw;
  height: ${props => {
    if (props.showResults) {
      return '80px';
    }
    return '100vh';
  }};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background: #238CD1;
  transition: height 0.6s ease-in-out;
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
`;

export const Container = styled.div`
  width: 100vw;
  position: relative;
`;

export const Input = styled.input`
  width: 100%;
  letter-spacing: 0.08em;
  box-sizing: border-box;
  background: #FFFFFF;
  opacity: 0.9;
  border-radius: ${props => {
    if (props.showTypeahead) {
      return '25px 25px 0 0';
    }
    return '50px';
  }};
  border: none;
  font-family: Roboto;
  font-weight: 300;
  outline: none;
  padding: 13pt 10pt 13pt 20pt;
  font-size: 18px;
  ${props => {
    if (props.showTypeahead) {
      return css`box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28)`;
    }
  }}

  &:focus {
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  }
  position: relative;
  z-index: 10;
  padding-right: 55px;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TypeaheadContainer = styled.div`
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  box-sizing: border-box;
  border-radius: 0 0 25px 25px;
  background: #E9F3FA;
  opacity: 1;
  font-family: Roboto;
  padding: 0 0 13pt 0;
  font-weight: 300;
  font-size: 18px;
  z-index: 9;
  position: relative;
  overflow: hidden;
`;

export const TypeaheadListWrapper = styled.div`
  max-height: ${props => {
    if (props.transition) {
      return '1000px';
    }
    return '0';
  }};
  @media screen and (max-width: 630px) {
    max-height: ${props => {
      if (props.transition) {
        return '300px';
      }
      return '0';
    }};
    overflow-y: scroll;
  }
  transition:  max-height 0.3s ease-in-out;
`;

export const SearchInputContainer = styled.div`
  max-width: 100vw;
  position: absolute;
  transition-property: top, left;
  transition-duration: .6s;
  transition-timing-function: ease-in-out;
  z-index: 10;

  @media screen and (min-width: 320px) {
    width: 310px;
    top: ${props => {
      if (props.showResults) {
        return '13px';
      }
      return '115px';
    }};
    left: ${props => {
      return 'calc(50% - 155px)';
    }};
  }
  @media screen and (min-width: 630px) {
    width: 420px;
    top: ${props => {
      if (props.showResults) {
        return '13px';
      }
      return '305px';
    }};
    left: ${props => {
      if (props.showResults) {
        return '188px';
      }
      return 'calc(50% - 210px)';
    }};
  }
  @media screen and (min-width: 860px) {
    width: 620px;
    top: ${props => {
      if (props.showResults) {
        return '13px';
      }
      return '305px';
    }};
    left: ${props => {
      if (props.showResults) {
        return '188px';
      }
      return 'calc(50% - 310px)';
    }};
  }
`;

export const VerticalSpacer = styled.div`
  width: 100%;
  height: ${props => props.height ? props.height : '10pt'}
`;

export const RowWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
  padding: 10pt 10pt 10pt 20pt;

  &:hover {
    background: rgba(196, 196, 196, 0.3);
  }
`;

export const Line = styled.div`
  display: block;
  margin: 0 auto;
  width: ${props => props.width || '90%'};
  opacity: 0.2;
  background: #000000;
  height: 1px;
`;

export const ResultsWrapper = styled.div`
  width: 800px;
  margin: 50px auto;
  max-width: 100vw;
  box-sizing: border-box;

  @media screen and (max-width: 860px) {
    width: calc(100vw - 20pt);
    margin: 50px 0;
    padding: 0 10pt;
  }
`;

export const ResultRowWrapper = styled.div`
  margin-bottom: 35px;
  max-width: 100%;
`;

export const ResultTitle = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 0 0 10px 0;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.08em;
`;

export const ResultSubTitle = styled.h3`
  margin: 0;
  font-size: 14px;
  opacity: 0.5;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.08em;
`;

export const MetaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;

  @media screen and (max-width: 630px) {
    justify-content: flex-start;
  }
`;

export const MetaTitle = styled.h4`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #007ACA;
  opacity: 0.7;
  margin: 0;
  letter-spacing: 0.08em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
`;

export const MetaValue = styled.h4`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.08em;
  color: #000000;
  letter-spacing: 0.08em;
  margin: 0;
  text-transform: capitalize;
`;

export const MetaItemWrapper = styled.div`
  margin-right: 10pt;
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 20px;
  transform: rotate(45deg);
  font-size: 50px;
  z-index: 101;
  cursor: pointer;
  opacity: .4;
`;
